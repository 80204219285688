
import Vue from "vue";

export default Vue.extend({
  name: "NewMentorcastData",
  data() {
    return {
      mentorcastData: [
        {
          label: (this as any).$t(
            "mentorcastHero.mentorcastDataMentorcastTitle"
          ),
          icon: "doc-title",
          value: this.$store.getters.newMentorcast.title
        },
        {
          label: (this as any).$t("mentorcastHero.mentorcastDataDescription"),
          icon: "document",
          value: this.$store.getters.newMentorcast.description
        },
        {
          label: (this as any).$t("mentorcastHero.mentorcastDataSeatPrice"),
          icon: "price",
          value: this.$store.getters.newMentorcast.information.price
        },
        {
          label: (this as any).$t(
            "mentorcastHero.mentorcastDataMaximumNoOfSeats"
          ),
          icon: "more-square",
          value: this.$store.getters.newMentorcast.information.availableSeats
        },
        {
          label: (this as any).$t("mentorcastHero.mentorcastDataDuration"),
          icon: "time-circle",
          value: this.$store.getters.newMentorcast.information.sessionDuration
        },
        {
          label: (this as any).$t("mentorcastHero.mentorcastDataStartDate"),
          icon: "calendar",
          value: this.$store.getters.newMentorcast.information.dateStarted
        },
        {
          label: (this as any).$t("mentorcastHero.mentorcastDataStartTime"),
          icon: "time-square",
          value: this.$store.getters.newMentorcast.information.timeStarted
        },
        {
          label: (this as any).$t("mentorcastHero.mentorcastDataPlatform"),
          icon: "platform",
          color: "primary",
          fontWeight: "semi-bold",
          value: this.$store.getters.newMentorcast.information.platform
        },
        {
          label: (this as any).$t("mentorcastHero.mentorcastDataAudience"),
          icon: "3users",
          value: this.$store.getters.newMentorcast.information.audience
        },
        {
          label: (this as any).$t("mentorcastHero.mentorcastDataLanguage"),
          icon: "language",
          value: this.$store.getters.newMentorcast.information.language
        }
      ]
    };
  }
});
